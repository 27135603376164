<template>
	<div class="pa-4">
		<v-data-table :search="search" :loading="loading" :headers="headers" :items="staffs" class="elevation-4 rounded"
			loading-text="Loading staffs">
			<template #top>
				<v-toolbar flat height="auto" class="rounded-t" color="#ECEFF1">
					<v-toolbar-title class="table-toolbar-title pr-2">{{ $route.name }}</v-toolbar-title>

					<v-responsive max-width="400" height="100%" class="pa-2"
						content-class="d-flex justify-center align-center">
						<text-field icon="mdi-magnify" v-model="search" name="search" label="Search staffs"
							class="full-width" :dense="true" />
					</v-responsive>
					<v-spacer />
					<v-btn color="primary lighten-1" @click="initCreate">
						<v-icon left>mdi-plus-circle</v-icon>
						New
					</v-btn>
				</v-toolbar>
			</template>
			<template #item.gender="{ item }">
				{{ (item.gender) || "-" }}
			</template>
			<template #item.branch="{ item }">
				{{ (item.my_branch) ? item.my_branch.name : "-" }}
			</template>
			<template #item.department="{ item }">
				{{ (item.my_department) ? item.my_department.name : "-" }}
			</template>
			<template #item.push_notification="{ item }">
				<v-simple-checkbox :ripple="false" :value="item.push_notification" />
			</template>
			<template #item.actions="{ item }">
				<tooltip-btn color="primary lighten-1" @click="editItem(item)" tooltip="Edit Staff" icon="mdi-pencil" />
				<tooltip-btn color="error lighten-1" @click="deleteItem(item)" icon="mdi-delete" tooltip="Delete Staff" />
			</template>
			<template #no-data>
				<v-btn color="primary" @click="initialize">
					Reset
				</v-btn>
			</template>
		</v-data-table>
		<staff-dialog @init="initialize" />
		<delete-dialog :model="model" @init="initialize" />
	</div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import TableMixin from "../mixins/TableMixin";
export default {
	name: "StaffsTable",
	components: {
		StaffDialog: () => import("@/components/Dialogs/StaffCUDialog"),
	},
	mixins: [TableMixin],
	data: () => ({
		model: "staff",
		headings: [
			{ text: "Name", value: "full_name" },
			{ text: "Username", value: "username", sortable: false },
			{ text: "Email", value: "email", sortable: false },
			{ text: "Mobile", value: "mobile", sortable: true },
			{ text: "Gender", value: "gender", sortable: false },
			{ text: "Branch", value: "branch", sortable: false },
			{ text: "Department", value: "department", sortable: false },
			{ text: "Push Notification", value: "push_notification", align: "center", sortable: false },
		],
	}),
	computed: {
		...mapGetters({
			"staffs": "staff/list"
		})
	},
	methods: {
		...mapMutations("staff", ["SET_DIALOG_STATE", "SET_FOR_EDIT", "SET_LIST", "SET_FORM_ERRORS"]),
		...mapMutations("toDelete", ["SET_ITEM_TO_DELETE"])
	},
}
</script>
