var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4"},[_c('v-data-table',{staticClass:"elevation-4 rounded",attrs:{"search":_vm.search,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.staffs,"loading-text":"Loading staffs"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"rounded-t",attrs:{"flat":"","height":"auto","color":"#ECEFF1"}},[_c('v-toolbar-title',{staticClass:"table-toolbar-title pr-2"},[_vm._v(_vm._s(_vm.$route.name))]),_c('v-responsive',{staticClass:"pa-2",attrs:{"max-width":"400","height":"100%","content-class":"d-flex justify-center align-center"}},[_c('text-field',{staticClass:"full-width",attrs:{"icon":"mdi-magnify","name":"search","label":"Search staffs","dense":true},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary lighten-1"},on:{"click":_vm.initCreate}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle")]),_vm._v(" New ")],1)],1)]},proxy:true},{key:"item.gender",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.gender) || "-")+" ")]}},{key:"item.branch",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.my_branch) ? item.my_branch.name : "-")+" ")]}},{key:"item.department",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.my_department) ? item.my_department.name : "-")+" ")]}},{key:"item.push_notification",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"ripple":false,"value":item.push_notification}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('tooltip-btn',{attrs:{"color":"primary lighten-1","tooltip":"Edit Staff","icon":"mdi-pencil"},on:{"click":function($event){return _vm.editItem(item)}}}),_c('tooltip-btn',{attrs:{"color":"error lighten-1","icon":"mdi-delete","tooltip":"Delete Staff"},on:{"click":function($event){return _vm.deleteItem(item)}}})]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}])}),_c('staff-dialog',{on:{"init":_vm.initialize}}),_c('delete-dialog',{attrs:{"model":_vm.model},on:{"init":_vm.initialize}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }